import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { gql, useQuery, useApolloClient } from '@apollo/client';

const idKey = process.env.GATSBY_STRAPI_VERSION === '5' ? 'documentId' : 'id';

const ME = gql`
  query {
    me {
      id
      ${idKey}
    }
  }
`;

const Context = createContext();

export const useUser = () => {
  const x = useContext(Context);
  if (!x) {
    throw new Error('Missing context in useUser, forgot to install the plugin?');
  }
  return x;
};

// eslint-disable-next-line import/no-mutable-exports
export let storageKey = null; // we import this in the authLink

const UserProvider = ({
  children,
  tokenKey,
  redirectToAfterRegister,
}) => {
  if (!tokenKey) {
    throw new Error('missing tokenKey in UserProvider');
  }
  storageKey = tokenKey;
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userDocumentId, setUserDocumentId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { resetStore } = useApolloClient();

  const logout = useCallback(() => {
    setUserId(null);
    setUserDocumentId(null);
    setToken(null);
    localStorage.removeItem(tokenKey);
  }, [tokenKey]);

  useEffect(() => {
    if (!token) {
      resetStore();
    }
  }, [token, resetStore]);

  useEffect(() => {
    const key = localStorage.getItem(tokenKey);
    if (key) {
      setToken(key);
      // we have a key, lets wait for the me to load before setting loaded.
    } else {
      // no key, lets go.
      setLoaded(true);
    }
  }, [tokenKey]);

  const setUser = useCallback((jwt) => {
    if (!jwt) {
      logout();
      return;
    }
    setToken(jwt);
    localStorage.setItem(tokenKey, jwt);
  }, [logout, tokenKey]);

  const { loading, data: meData, error: meError } = useQuery(ME, {
    skip: !token,
    fetchPolicy: 'no-cache',
    context: {
      useAuth: true,
    },
  });
  const { me: { documentId, id } } = meData || { me: { id: null } };
  useEffect(() => {
    // when loading, we might be switching accounts
    // if so, the old me is in cache
    // so dont set the ID until loading is done
    if (!loading && (documentId || id)) {
      setUserDocumentId(documentId);
      setUserId(id);
      setLoaded(true);
    }
  }, [documentId, id, loading]);
  useEffect(() => {
    if (meError) {
      console.error('FAILED TO LOAD ME');
      console.error(meError);
      logout();
      setLoaded(true); // this might have happened on page load
    }
  }, [meError, logout]);

  const value = useMemo(() => ({
    loaded,
    isLoggedIn: Boolean(userDocumentId || userId),
    setUser,
    userId,
    userDocumentId,
    redirectToAfterRegister,
  }), [
    loaded,
    setUser,
    userId,
    userDocumentId,
    redirectToAfterRegister,
  ]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export default UserProvider;
